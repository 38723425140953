import { add, subDays } from 'date-fns';
//
import _mock from '../_mock';
import { randomInArray, randomNumberRange } from '../utils';

// ----------------------------------------------------------------------

export const _orders = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  orderNumber: `${17048 + index}`,
  taxes: 5,
  discount: 10,
  sent: randomNumberRange(1, 10),
  subTotalPrice: _mock.number.price(index + 1),
  totalPrice: _mock.number.price(index + 1),
  createDate: subDays(new Date(), index),
  dueDate: add(new Date(), { days: index + 15, hours: index }),
  status: randomInArray(['delivered', 'undelivered']),
  products: [...Array(3)].map((_, index) => ({
    id: _mock.id(index),
    title: _mock.products.title(index),
    description: _mock.text.description(index),
    quantity: 5,
    price: _mock.number.price(index),
    total: _mock.number.price(index),
  })),
  orderFrom: {
    id: _mock.id(index),
    name: _mock.name.fullName(index),
    address: _mock.address.fullAddress(index),
    company: _mock.company(index),
    email: _mock.email(index),
    phone: _mock.phoneNumber(index),
  },
  orderTo: {
    id: _mock.id(index + 1),
    name: _mock.name.fullName(index + 1),
    address: _mock.address.fullAddress(index + 1),
    company: _mock.company(index + 1),
    email: _mock.email(index + 1),
    phone: _mock.phoneNumber(index + 1),
  },
  items: [...Array(3)].map((_, index) => ({
    id: _mock.id(index),
    title: _mock.text.title(index),
    description: _mock.text.description(index),
    quantity: 5,
    price: _mock.number.price(index),
    total: _mock.number.price(index),
    // service: randomInArray([
    //   'full stack development',
    //   'backend development',
    //   'ui design',
    //   'ui/ux design',
    //   'front end development',
    // ]),
  })),
}));

export const _orders1 = [...Array(5)].map((_, index) => ({
  createdAt: new Date('2023-06-20T17:24:54.547Z'),
  id: '1',
  order_amount: 145,
  order_status: 'Processing',
  payment_id: null,
  payment_mode: 'CASH_ON_DELIVERY',
  updated_at: new Date('2023-06-20T17:24:54.547Z'),
  billing_address: {
    address: 'c3 1305 srs residency sec 88 faridabad, jhajjar, Haryana, undefined',
    address_type: 'home',
    city: 'jhajjar',
    full_name: 'Virender',
    id: 1,
    phone: '09050552555',
    state: 'Haryana',
  },
  order_product: [
    {
      id: 1,
      quantity: 2,
      product: {
        id: 1,
        name: 'buffalo milk',
        description: 'buffalo milk dfjksbkbsaksaf sadfbkdjsbfkjdsksabkjkas',
        price: 75,
        priceSale: 70,
        inventoryType: 'stock',
        available: 100,
        sold: 0,
        is_subscribed: false,
        createdAt: '2023-06-19T04:38:47.985Z',
        updatedAt: '2023-06-19T04:38:47.985Z',
      },
    },
  ],
  shipping_address: {
    address: 'c3 1305 srs residency sec 88 faridabad, jhajjar, Haryana, undefined',
    address_type: 'home',
    city: 'jhajjar',
    full_name: 'Virender',
    id: 1,
    phone: '09050552555',
    state: 'Haryana',
  },
}));

export const _orderAddressFrom = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  address: _mock.address.fullAddress(index),
  company: _mock.company(index),
  email: _mock.email(index),
  phone: _mock.phoneNumber(index),
}));

export const _orderAddressTo = [...Array(16)].map((_, index) => ({
  id: _mock.id(index + 1),
  name: _mock.name.fullName(index + 1),
  address: _mock.address.fullAddress(index + 1),
  company: _mock.company(index + 1),
  email: _mock.email(index + 1),
  phone: _mock.phoneNumber(index + 1),
}));
