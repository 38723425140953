import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { IProductState, ICheckoutCartItem } from '../../@types/product';
//
import { dispatch } from '../store';
import { demoProducts } from './products';
import { IOrderState } from 'src/@types/order';
import { IAddressState } from 'src/@types/bproducts';

// ----------------------------------------------------------------------

const initialState: IAddressState = {
  isLoading: false,
  error: null,
  addresses: [],
  address: null,
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORDERS
    getAddressesSuccess(state, action) {
      state.isLoading = false;
      state.addresses = action.payload;
    },

    // GET ORDER
    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.address = action.payload;
    },

    //PLACE ORDER

    // GET MAIL
    // getMailSuccess(state, action) {
    //     const mail = action.payload;

    //     state.mails.byId[mail.id] = mail;
    //     if (!state.mails.allIds.includes(mail.id)) {
    //       state.mails.allIds.push(mail.id);
    //     }
    //   },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAddresses() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/users/address');
      // console.log(response.data);
      dispatch(slice.actions.getAddressesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function placeOrder(body: Record<string, string>) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/orders', body);
      // dispatch(slice.actions.getMailsSuccess(response.data.mails));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMail(mailId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/mail/mail', {
        params: { mailId },
      });
      // dispatch(slice.actions.getMailSuccess(response.data.mail));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
