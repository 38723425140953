import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
//
import Footer from './Footer';
import Header from './Header';
import navConfig from './nav/config';
import { bgGradient } from 'src/utils/cssStyles';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const routeInfo = navConfig.filter((item) => item.path === pathname)[0] || { bgColor: '#e7ebce' };
  const isHome = pathname === '/';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />

      <Box
        component="main"
        sx={{
          bgcolor: bgGradient({
            direction: '135deg',
            startColor: `${routeInfo.bgColor}30`,
            endColor: '#d5ceeb',
          }),
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 8, md: 11 },
          }),
        }}
      >
        <Outlet />
      </Box>

      <Footer />
    </Box>
  );
}
